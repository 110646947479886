import React, { Component } from "react";
import {
  Loader,
  Button,
  Form,
  Image,
  Table,
  Icon,
  Statistic,
  Grid,
  Label,
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import { Field, reduxForm, change, reset } from "redux-form";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderField from "../../components/renderField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import moment from "moment";
import {
  getReportoffenses,
  postReportoffenses,
  updateReportoffenses,
} from "./service";
import Wrap from "../../components/wrap";

const validate = (values) => {
  const errors = {};
  if (!values.title) errors.title = true;
  if (!values.ondate) errors.ondate = true;
  if (!values.detail) errors.detail = true;
  return errors;
};

class Discipline extends Component {
  state = {
    id: this.props && this.props.id,
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    data: [],
    open: false,
    loading: true,
    language: "en",
    showData: [],
    total: 100
  };

  componentDidMount() {
    let { studentData } = this.props;
    this.getData(studentData?.user_id);
    if (this.props.initialValues) {
      this.setState({ language: this.props.initialValues.language });
    }
  }

  getData = (id) => {
    getReportoffenses(id).then((res) => {
      if (res && res.status === 200) {
        let score = res.data.reduce(function (a, b) {
          return a + b['score'];
        }, 0);
        this.setState({ data: res.data, total: 100 - score });
      }
    });
    this.setState({ loading: false });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  onClickNew = () => {
    this.setState({ open: true });
  };
  onClickShow = (data) => {
    this.setState({ show: true, showData: data });
  };

  onClickEdit = (data) => {
    this.props.dispatch(change("discipline", "id", data.id));
    this.props.dispatch(change("discipline", "title", data.title));
    this.props.dispatch(change("discipline", "ondate", data.ondate));
    this.props.dispatch(change("discipline", "detail", data.detail));
    this.props.dispatch(change("discipline", "language", data.language));
    this.setState({ open: true, language: data.language, showData: data });
  };
  close = () => {
    this.setState({ open: false, show: false, language: "en" });
    this.props.dispatch(reset("discipline"));
  };

  onSubmit = (values) => {
    this.setState({ loading: true });
    values.language = this.state.language;
    let { studentData } = this.props;
    if (values.id) {
      updateReportoffenses(values.id, values).then((res) => {
        if (res && res.status === 200) {
          this.getData(studentData.user_id);
        }
      });
    } else {
      postReportoffenses(values).then((res) => {
        if (res && res.status === 201) {
          this.getData(studentData.user_id);
        }
      });
    }

    this.close();
  };

  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting, studentData } = this.props;

    if (state.loading)
      return <Loader active inline="centered" content="Loading" />;
    return (
      <div>
        <br />
        <br />
        <Grid columns="1">
          <Grid.Column textAlign="center">
            <Statistic color="orange">
              <Statistic.Label>Discipline Score</Statistic.Label>
              <Statistic.Value>{state.total}/100</Statistic.Value>
              <Statistic.Label>{(state.total * 30) / 100} %</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            {state.bacUserData && state.bacUserData.id === state.id && (
              <MainButton
                size="small"
                onClick={this.onClickNew}
                text="Create Request"
              />
            )}
            {/* <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Create Request"
            /> */}
          </Grid.Column>
          <Grid.Column>
            {state.data && state.data.length > 0 ? (
              <Table celled padded compact style={{ marginBottom: 10 }}>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Date time</Table.HeaderCell>
                    <Table.HeaderCell width="6">Offense</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell width="1">Score</Table.HeaderCell>
                    <Table.HeaderCell>Action By</Table.HeaderCell>
                    <Table.HeaderCell>Action At</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.data.map((item) => (
                    <Table.Row>
                      <Table.Cell textAlign="center">
                        {moment(item.ondate, "DD-MM-YYYY").format(
                          "D MMMM YYYY"
                        )}
                      </Table.Cell>
                      <Table.Cell className="bold">
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onClickShow(item)}
                        >
                          {item.title}{" "}
                        </p>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.status === "Requested" ? (
                          <Label className="statusLabel" color="yellow">
                            Requested
                          </Label>
                        ) : item.status === "Completed" ? (
                          <Label className="statusLabel" color="green">
                            Completed
                          </Label>
                        ) : <Label className="statusLabel" color="blue">
                          Penalized
                        </Label>}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: 'center' }}>{item.score}</Table.Cell>
                      <Table.Cell>{item.passedBy}</Table.Cell>
                      <Table.Cell style={{ textAlign: 'center' }}>{item.passedAt}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Icon
                          link
                          circular
                          color="orange"
                          name="file alternate outline"
                          onClick={() => this.onClickShow(item)}
                        />
                        {item.status === "Requested" && (
                          <Icon
                            link
                            circular
                            color="orange"
                            name="edit"
                            onClick={() => this.onClickEdit(item)}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <p style={{ color: "rgb(115, 115, 115)" }}>
                No description available
              </p>
            )}
          </Grid.Column>
        </Grid>
        <ModalForm
          open={state.show}
          icon="file alternate"
          size="small"
          content="Report Offense"
          close={this.close}
        >

          {state.showData.language === "th" ?
            <Wrap>
              <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <h3 style={{ textAlign: 'center' }}>
                        <Image
                          spaced
                          style={{ height: "50px" }}
                          src="../img/logo-bac.png" />
                        Bangkok Aviation Center Co.,Ltd.
                      </h3>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <b>บริษัท บางกอกเอวิเอชั่นเซ็นเตอร์ จํากัด</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very' style={{ marginTop: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>วันที่</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>
                        {moment(state.showData.created_at).format("DD-MM-YYYY")}
                      </b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>เรื่อง</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>รายงานชี้แจง</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>เรียน</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>ครูปกครอง ฝ่ายปฏิบัติการบิน</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <p
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        ตามที่ กระผม/ดิฉัน&nbsp;&nbsp;
                        <b>
                          {state.showData
                            ? `${state.showData.titleTh} ${state.showData.firstNameTh} ${state.showData.lastNameTh}   `
                            : ""}
                        </b>
                        &nbsp;&nbsp;ศิษย์การบิน รุ่น &nbsp;&nbsp;
                        <b>
                          {state.showData ? state.showData.class
                            : ""}
                        </b>
                        &nbsp;&nbsp; ขอรายงานชี้แจงเรื่อง &nbsp;&nbsp;
                        {state.showData.title} เหตุการณ์เมื่อวันที่{" "}
                        {state.showData.ondate}&nbsp;&nbsp; รายละเอียดดังนี้
                        &nbsp;&nbsp;{state.showData.detail}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <b
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        จึงเรียนมาเพื่อพิจารณา
                      </b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '50%', border: 'none' }}>
                    </Table.Cell>
                    <Table.Cell style={{ width: '50%', border: 'none', textAlign: 'center' }}>
                      (ลงชื่อ)........
                      <b>
                        {state.showData
                          ? ` ${state.showData.titleTh} ${state.showData.firstNameTh} ${state.showData.lastNameTh} `
                          : ""}
                      </b>
                      ........
                      <br />
                      ศิษย์การบิน
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Wrap>
            : <Wrap>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <h3 style={{ textAlign: 'center' }}>
                        <Image
                          spaced
                          style={{ height: "50px" }}
                          src="../img/logo-bac.png" />
                        Bangkok Aviation Center Co.,Ltd.
                      </h3>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <b>Bangkok Aviation Center Co.,Ltd.</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>Date</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>
                        {moment(state.showData.created_at).format("DD-MM-YYYY")}
                      </b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>Subject</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>Report Offense</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>To</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>Director BangkokvAviation Center ( Gurdian )</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <p
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        According to Sir/Madam &nbsp;&nbsp;
                        <b>
                          {state.showData
                            ? `${state.showData.titleEng} ${state.showData.firstNameEng} ${state.showData.lastNameEng}   `
                            : ""}
                        </b>
                        &nbsp;&nbsp;Students Pilot clasno &nbsp;&nbsp;
                        <b>
                          {state.showData ? state.showData.class
                            : ""}
                        </b>
                        &nbsp;&nbsp; Committed an offense &nbsp;&nbsp;
                        {state.showData.title} On date {state.showData.ondate}
                        &nbsp;&nbsp; Detail as follow &nbsp;&nbsp;
                        {state.showData.detail}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <b
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        For your information
                      </b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '50%', border: 'none' }}>
                    </Table.Cell>
                    <Table.Cell style={{ width: '50%', border: 'none', textAlign: 'center' }}>
                      (Sign)........
                      <b>
                        {state.showData
                          ? ` ${state.showData.titleEng} ${state.showData.firstNameEng} ${state.showData.lastNameEng} `
                          : ""}
                      </b>
                      ........
                      <br />
                      Student Pilot
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Wrap>
          }
        </ModalForm>

        <ModalForm
          open={state.open}
          // open={true}
          icon="file alternate"
          size="small"
          content="Report Offense"
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onSubmit)}>
            <Grid>
              <Grid.Row style={{ paddingBottom: "20px" }}>
                <Grid.Column width="16">
                  <Button.Group floated="right" size="small" compact>
                    <Button
                      type="button"
                      active={state.language === "en"}
                      content="English"
                      onClick={() => this.setState({ language: "en" })}
                    />
                    <Button
                      type="button"
                      active={state.language === "th"}
                      content="ไทย"
                      onClick={() => this.setState({ language: "th" })}
                    />
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="2" style={{ paddingBottom: "30px" }}>
                <Grid.Column width="1">

                </Grid.Column>
                <Grid.Column width="14" style={{ textAlign: "center" }}>
                  <h2>Bangkok Aviation Center Co.,Ltd.</h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: "0px" }}>
                <Grid.Column>
                  <h4>
                    {state.language === "en"
                      ? "Bangkok Aviation Center Co.,Ltd."
                      : "บริษัท บางกอกเอวิชั่นเซ็นเตอร์ จำกัด"}
                  </h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                <Grid.Column width="1">
                  {" "}
                  <h4>{state.language === "en" ? "Date" : "วันที่"}</h4>
                </Grid.Column>
                <Grid.Column>
                  <h4>{moment().format("DD-MM-YYYY")}</h4>{" "}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                <Grid.Column width="1">
                  <h4>{state.language === "en" ? "Subject" : "เรื่อง"}</h4>
                </Grid.Column>
                <Grid.Column>
                  <h4>
                    {state.language === "en"
                      ? "Report Offense"
                      : "รายงานชี้แจง"}
                  </h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                <Grid.Column width="1">
                  <h4>{state.language === "en" ? "To" : "เรียน"}</h4>
                </Grid.Column>
                <Grid.Column>
                  <h4>
                    {state.language === "en"
                      ? "Director BangkokvAviation Center ( Gurdian )"
                      : "ครูปกครอง ฝ่ายปฏิบัติการบิน"}
                  </h4>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row
                columns="2"
                style={{ paddingBottom: "14px", paddingTop: "24px" }}
              >
                <Grid.Column width="1"> </Grid.Column>
                <Grid.Column width="14">
                  {state.language === "en" ? (
                    <Wrap>
                      According to Sir/Madam&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>
                        {studentData
                          ? `${studentData.titleEng} ${studentData.firstNameEng} ${studentData.lastNameEng}   `
                          : ""}{" "}
                      </b>
                      &nbsp;&nbsp;&nbsp;&nbsp;Students Pilot clasno
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b>
                        {studentData && studentData.class
                          ? studentData.class.name
                          : ""}{" "}
                      </b>
                    </Wrap>
                  ) : (
                    <Wrap>
                      ตามที่ กระผม/ดิฉัน&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>
                        {studentData
                          ? `${studentData.titleTh} ${studentData.firstNameTh} ${studentData.lastNameTh}   `
                          : ""}
                      </b>
                      &nbsp;&nbsp;&nbsp;&nbsp;ศิษย์การบิน รุ่น
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b>
                        {studentData && studentData.class
                          ? studentData.class.name
                          : ""}
                      </b>
                    </Wrap>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                <Grid.Column width="4">
                  {state.language === "en"
                    ? "Committed an offense"
                    : "ขอรายงานชี้แจงเรื่อง"}
                </Grid.Column>
                <Grid.Column width="12">
                  <Field name="title" component={renderField} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="4" style={{ padding: "0px" }}>
                <Grid.Column width="4">
                  {state.language === "en" ? "On date" : "เหตุการณ์เมื่อวันที่"}{" "}
                </Grid.Column>
                <Grid.Column width="6">
                  <Field name="ondate" component={renderDateOnlyField} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ padding: "0px" }}>
                <Grid.Column width="4">
                  {state.language === "en"
                    ? "Detail as follow"
                    : "รายละเอียดดังนี้"}{" "}
                </Grid.Column>
                <Grid.Column width="12">
                  <Field name="detail" component={renderTextAreaField} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                <Grid.Column width="1"> </Grid.Column>
                <Grid.Column>
                  <b>
                    {state.language === "en"
                      ? "For your information"
                      : "จึงเรียนมาเพื่อพิจารณา"}{" "}
                  </b>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: "0px" }}>
                <Grid.Column width="8"> </Grid.Column>
                <Grid.Column width="8" style={{ textAlign: "center" }}>
                  {state.language === "en" ? (
                    <Wrap>
                      <b>
                        {studentData
                          ? `${studentData.titleEng} ${studentData.firstNameEng} ${studentData.lastNameEng}   `
                          : ""}
                      </b>
                      <br />
                      <br /> Student Pilot
                    </Wrap>
                  ) : (
                    <Wrap>
                      <b>
                        {studentData
                          ? `${studentData.titleTh} ${studentData.firstNameTh} ${studentData.lastNameTh}   `
                          : ""}
                      </b>
                      <br />
                      <br /> ศิษย์การบิน
                    </Wrap>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <br />
            <br />
            <br />
            <Button
              floated="left"
              type="submit"
              content="Submit"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
          <br />
        </ModalForm>
      </div>
    );
  }
}

// export default withRouter(Timeline);
// export default withRouter(Documents);
export default Discipline = reduxForm({
  // a unique name for the form
  form: "discipline",
  validate,
  enableReinitialize: true,
})(Discipline);
